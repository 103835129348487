<template>
  <v-container class="pa-0" fluid>
    <div class="container">
      <div class="row">
        <div class="col-4">
          <img class="avatar-nick" src="../img/sou-nick.png" />
        </div>
        <div class="col-7">
          Olá João, eu sou o
          <span class="name-nick">NICK</span>
          <span class="by-thinkcare">by ThinkCare</span>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <v-badge
            :content="9"
            style="display: block"
            bordered
            color="primary2"
            overlap
          >
            <v-btn
              style="width: 100%"
              class="white--text"
              color="secondary2"
              depressed
            >
              Avisos
            </v-btn>
          </v-badge>
        </div>
        <div class="col-6">
          <v-badge
            :content="26"
            style="display: block"
            bordered
            color="primary2"
            overlap
          >
            <v-btn
              style="width: 100%"
              class="white--text"
              color="secondary2"
              depressed
            >
              FAQ
            </v-btn>
          </v-badge>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div>
            <span class="title-mais-acessados">Serviços mais acessados</span>
          </div>
          <hr />
        </div>
        <div class="box-mais-acessados-main col-12 col-sm-7">
          <div class="box-mais-acessados">Formulário 2° via da carteirinha</div>
        </div>
        <div class="box-mais-acessados-main col-12 col-sm-7">
          <div class="box-mais-acessados">
            Formulário de inclusão de dependentes
          </div>
        </div>
        <div class="box-mais-acessados-main col-12 col-sm-7">
          <div class="box-mais-acessados">Rede credenciada do meu plano</div>
        </div>
        <div class="box-mais-acessados-main col-12 col-sm-7">
          <div class="box-mais-acessados">
            Formulário de remoção de dependentes
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div>
            <span class="title-ultimos-atendimentos">
              Últimos Atendimentos
              <v-btn
                class="ml-5"
                type="submit"
                color="secondary2"
                @click="$router.push('/atendimentos')"
              >
                <span class="white--text">Ver</span>
                <v-icon class="ml-3" small color="white">fas fa-plus</v-icon>
              </v-btn>
            </span>
          </div>
        </div>
        <div class="col-6 col-sm-4">
          <div class="box-ultimos-atendimento">
            <p class="recomendacao-title" style="text-align: center">#10013</p>
            <p class="recomendacao-text" style="text-align: center">Aberto</p>
          </div>
        </div>
        <div class="col-6 col-sm-4">
          <div class="box-ultimos-atendimento">
            <p class="recomendacao-title" style="text-align: center">#10013</p>
            <p class="recomendacao-text" style="text-align: center">Fechado</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div>
            <span class="title-recomendacoes-nick">Recomendações do NICK</span>
          </div>
        </div>
        <div class="col-6">
          <div class="box-recomendacao-nick">
            <span class="recomendacao-title" style="display: block"
              >Vacina</span
            >
            <span
              class="recomendacao-text"
              style="display: block; font-size: 12px; font-weith: bold"
              >febre amarela...</span
            >
          </div>
        </div>
        <div class="col-6">
          <div class="box-recomendacao-nick">
            <span class="recomendacao-title" style="display: block"
              >Artigos</span
            >
            <span
              class="recomendacao-text"
              style="display: block; font-size: 10px; font-weith: bold"
              >Como emagrecer de forma saudadevel...</span
            >
          </div>
        </div>
        <div class="col-6">
          <div class="box-recomendacao-nick">
            <span class="recomendacao-title" style="display: block">Dicas</span>
            <span
              class="recomendacao-text"
              style="display: block; font-size: 12px; font-weith: bold"
              >9 alimentos para dormir melhor...</span
            >
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-8">
          <v-text-field
            placeholder="Falar com nick"
            dense
            color="white"
          ></v-text-field>
        </div>
        <div class="col-3">
          <v-btn @click="goAssistente()" type="submit" color="secondary2">
            <v-icon small color="white">fas fa-share</v-icon>
          </v-btn>
        </div>
      </div> -->

      <!-- <div class="row">
        <div class="col-8">
          <v-text-field
            placeholder="Falar com nick (Einstein)"
            dense
            color="white"
          ></v-text-field>
        </div>
        <div class="col-3">
          <v-btn @click="goAssistente2()" type="submit" color="secondary2">
            <v-icon small color="white">fas fa-share</v-icon>
          </v-btn>
        </div>
      </div> -->

      <div class="row">
        <div class="col-12">
          <v-btn block @click="$router.push('/ajuda2')">
            Ir Para Ajuda 2
          </v-btn>
        </div>
      </div>
    </div>
    <iframe-nick :src="''" />
  </v-container>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import iframenick from "./iframenick";
export default {
  components: {
    "iframe-nick": iframenick
  },
  mounted() {},
  methods: {
    ...mapMutations(["showmsg", "updateBadges"]),
    goAssistente() {
      this.$util.link("assistenteboot", {
        boot:
          "https://app.engati.com/static/standalone/bot.html?bot_key=0afb504e29214edf&env=p",
      });
    }
  },
  computed: {
    ...mapState(["badges"]),
  },
  data: () => ({
    iframeurl: null,
    items: [],
  }),
};
</script>
<style>
.recomendacao-title {
  color: #2a244e;
  font-weight: bold;
}
.recomendacao-text {
  color: #666;
  font-weight: bold;
}

.box-acoes-alerta {
  width: 25px;
  height: 25px;
  position: absolute;
  margin-top: -56px;
}

.title-recomendacoes-nick {
  font-size: 15px;
  color: #333;
  font-weight: bold;
}

.box-recomendacao-nick {
  background: #eee;
  padding: 30px;
  border-radius: 7px;
}

.box-ultimos-atendimento {
  background: #eee;
  padding: 5px;
  border-radius: 7px;
}

.title-ultimos-atendimentos {
  font-size: 15px;
  color: #ea4965;
  font-weight: bold;
}

.box-mais-acessados-main {
  padding-top: 0px !important;
  padding-right: 0px;
  padding-left: 13px !important;
  padding-bottom: 10px !important;
  color: #666;
}

.box-mais-acessados {
  border: solid 1px #eee;
  padding: 5px;
}

.title-mais-acessados {
  font-size: 15px;
  color: #333;
  font-weight: bold;
}

.avatar-nick {
  width: 100px;
  height: 100px;
  border-radius: 40%;
}

.box-acoes {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  padding: 10px;
  border-radius: 7px;
}

.by-thinkcare {
  font-size: 10px;
  color: #ea4965;
  font-weight: 600;
  display: block;
}
.name-nick {
  font-size: 15px;
  display: block;
}
</style>
