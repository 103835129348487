<template>
  <div id="iframe-wrapper" :style="iframe.wrapperStyle">
  </div>
</template>
<script>
export default {
  props: {
    src: {
      type: String,
      default: () => "",
    },
  },
  data: () => ({
    loaded: false,
    embedded_svc: null,
    iframe: {
      style: {
        position: "absolute",
        width: "100%",
        height: "95%",
      },
      wrapperStyle: {
        overflow: "hidden",
      },
    },
  }),

  methods: {
    voltar() {
      this.$emit("voltar");
    },
    initESW(gslbBaseURL) {
      window.embedded_svc.settings.displayHelpButton = true; //Ou falso
      window.embedded_svc.settings.language = "pt-BR"; //Por exemplo, insira "pt" ou "pt-BR"

      window.embedded_svc.settings.defaultMinimizedText = "Falar com Nick"; //(Assume como padrão o Chat com um especialista)
      window.embedded_svc.settings.disabledMinimizedText = "Chat Offline"; //(Assume como padrão Agente off-line)

      window.embedded_svc.settings.loadingText = "Carregando..."; //(Assume como padrão Carregando)
      //window.embedded_svc.settings.storageDomain = 'https://localhost:8080'; //(Define o domínio de sua implantação para que seus visitantes possam navegar em subdomínios durante uma sessão de chat)

      // Configurações para Chat
      //this.embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
      // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
      // Returns a valid button ID.
      //};
      //this.embedded_svc.settings.prepopulatedPrechatFields = {}; //Define o preenchimento automático de campos de formulário de pré-chat
      //this.embedded_svc.settings.fallbackRouting = []; //Uma matriz de IDs de botão, IDs de usuário ou userId_buttonId
      //this.embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Padronizado para Entre em contato conosco)

      window.embedded_svc.settings.enabledFeatures = ["LiveAgent"];
      window.embedded_svc.settings.entryFeature = "LiveAgent";

      window.embedded_svc.init(
        "https://thinkcare.my.salesforce.com",
        "https://portalthinkcare.force.com/cotador",
        gslbBaseURL,
        "00D6g000000F70m",
        "Atendimento",
        {
          baseLiveAgentContentURL:
            "https://c.la1-c2-ia5.salesforceliveagent.com/content",
          deploymentId: "5726g0000009pua",
          buttonId: "5736g0000009rbS",
          baseLiveAgentURL: "https://d.la1-c2-ia5.salesforceliveagent.com/chat",
          eswLiveAgentDevName: "Atendimento",
          isOfflineSupportEnabled: false,
        }
      );
    },
  },

  mounted() {
    if (!window.embedded_svc) {
      var s = document.createElement("script");
      s.setAttribute(
        "src",
        "https://thinkcare.my.salesforce.com/embeddedservice/5.0/esw.min.js"
      );
      s.onload = () => {
        this.initESW(null);
      };
      document.body.appendChild(s);
    } else {
      this.initESW("https://service.force.com");
    }

    this.loaded = true;
  },
};
</script>

<style>
.helpButton {
  margin-bottom: 10% !important;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #ffffff;
  font-family: "Salesforce Sans", sans-serif;
  max-width: 15em;
  width: 14em;
  font-size: 1rem;
  color: #4d4090;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #000000;
}

@font-face {
  font-family: "Salesforce Sans";
  src: url("https://www.sfdcstatic.com/system/shared/common/assets/fonts/SalesforceSans/SalesforceSans-Regular.woff")
      format("woff"),
    url("https://www.sfdcstatic.com/system/shared/common/assets/fonts/SalesforceSans/SalesforceSans-Regular.ttf")
      format("truetype");
}

.message {
  background: none;
  border: 0px solid #000;
  font-size: 1rem;
  color: #4d4090;
  font-weight: bold;
}

.embeddedServiceLiveAgentStateChatHeader:not(.alert) .message {
  display: none;
}

body a:hover {
  text-decoration: underline;
  color: #ffff;
}

body button,
body .x-btn,
body .btn,
body .btnDisabled,
body .btnCancel,
body .menuButton .menuButtonButton {
  background: none;
}
</style>
